/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import { Container } from "react-bootstrap"

const LegalNoticePage = () => {
  return (
    <Layout footerHalf={false}>
      <SEO title="Mentions légales" />

      <Section className="py-5" css={{ marginTop: "60px" }}>
        <Container className="content">
          <h1 className="mb-0">Mentions légales</h1>
        </Container>
      </Section>
      <Section className="pt-0 content">
        <Container>
          <h2>1.1 Édition/Publication du site :</h2>
          <p>
            <strong>Simulassur By Elois</strong> Siège social : 120, avenue du
            Général Leclerc CS 10029 - 75680 PARIS CEDEX 14 SARL au capital de
            100 000 € – RCS Nanterre B 483 413 258 N° Orias : 07 019 295
          </p>
          <h2>1.2 Articles :</h2>
          <h3>Protection de vos données personnelles</h3>
          <p>
            Les informations qui vous concernent sont destinées à Simulassur By
            Elois et aux prestataires pouvant traiter votre demande de devis. Le
            présent site a fait l'objet d'une déclaration à la CNIL sous N°
            738133. A ce titre, vous disposez d'un droit d'accès, de
            modification, de rectification et de suppression des données qui
            vous concernent (art. 34 de la loi "Informatique et Libertés"). Pour
            l'exercer, adressez vous à Simulassur By Elois - Service
            consommateur - 120, avenue du Général Leclerc CS 10029 - 75680 PARIS
            CEDEX 14 ou infoslegales@magnolia.fr. Conformément à l'article
            L.223-2 du Code de la consommation, vous disposez du droit à vous
            inscrire sur la liste d'opposition au démarchage téléphonique
            Bloctel gérée par Opposetel.
          </p>
          <h3>Information</h3>
          <p>
            En soumettant ces informations, vous autorisez Simulassur By Elois à
            vous contacter par téléphone pour vous conseillez en utilisant ces
            informations ou pour obtenir des informations supplémentaires
            indispensables au traitement de votre demande de devis. Vous
            acceptez la politique de confidentialité de ce site et reconnais
            qu’en tant que visiteur vous pouvez recevoir des rappels de devis et
            des promotions spéciales, dont vous pouvez vous désinscrire à tout
            moment. Simulassur By Elois est Immatriculation à l'ORIAS sous le N°
            07 019 295
          </p>
          <h3>Avis relatif à la sécurité :</h3>
          <p>
            Afin d’assurer sa sécurité et de garantir son accès à tous, ce site
            Internet emploie des logiciels pour contrôler les flux sur le site,
            pour identifier les tentatives non autorisées de connexion ou de
            changement de l’information, ou toute autre initiative pouvant
            causer d’autres dommages. Les tentatives non autorisées de
            chargement d’information, d’altération des informations, visant à
            causer un dommage et d’une manière générale toute atteinte à la
            disponibilité et l’intégrité de ce site sont strictement interdites
            et seront sanctionnées par le code pénal. Ainsi l’article 323-1 du
            code pénal prévoit que le fait d’accéder ou de se maintenir
            frauduleusement, dans tout ou partie d’un système de traitement
            automatisé de données (c’est le cas d’un site Internet) est puni de
            deux ans d’emprisonnement et de 30000 Euros d’amende. L’article
            323-3 du même code prévoit que le fait d’introduire frauduleusement
            des données dans un système de traitement automatisé ou de supprimer
            ou de modifier frauduleusement les données qu’il contient est puni
            de cinq ans d’emprisonnement et de 75000 Euros d’amende.
          </p>
          <h3>Les cookies :</h3>
          <p>
            Ce site utilise des cookies. Les cookies sont des fichiers texte
            contenant des informations permettant d’identifier les visiteurs
            récurrents pour la durée de leur visite sur ce site. Les cookies
            sont enregistrés sur le disque dur de votre ordinateur et n’y
            causent aucun dommage. Ils nous permettent d’augmenter la
            convivialité du site, de mesurer l’audience et d’adapter précisément
            les contenus à vos besoins. Les cookies servent également à vérifier
            si votre ordinateur a déjà été connecté à nos pages. Seul le cookie
            enregistré sur votre ordinateur est alors identifié. Si vous avez
            donné votre accord, des données personnelles seront enregistrées
            sous la forme de cookies, par exemple pour faciliter l’accès
            sécurisé à notre site en évitant la saisie à répétition de votre
            identifiant et mot de passe.
          </p>
          <p>
            Les cookies du site ne contiennent pas de données permettant de vous
            identifier personnellement, et ils sont conçus pour être utilisés
            uniquement par Simulassur by Eloïs.
          </p>
          <p>
            Nous vous informons que vous pouvez vous opposer à l’enregistrement
            de ces « cookies » en utilisant une option de configuration de votre
            navigateur, modalités détaillées sur le site{" "}
            <a
              className="text-secondary"
              href="http://www.cnil.fr"
              target="_blank"
              rel="noreferrer"
            >
              http://www.cnil.fr
            </a>
            .
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default LegalNoticePage
